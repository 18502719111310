<template>
    <div class="row ">
        <div class="correspondence mt-0" style="padding: 20px;">
            <div class="chat-content">
                <div v-if="joined" class=" mb-3" v-for="item in receive_message">
                    <div class="row" v-if="item.sent_by_user == 1">
                        <div class="col-md-7 d-flex mb-2">
                            <div class="logo" style="margin-inline-end: 5px;">
                                <img width="32" :src="Avatar" alt="">
                            </div>
                            <div class="card-white">
                                <div class="content">
                                    {{ item.content }}
                                </div>
                                <div class="date">
                                    {{ formatDate(item.created_at) }}
                                </div>
                            </div>
                        
                        </div>
                        <div class="col-md-5"></div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-7"></div>
                        <div class="col-md-5 d-flex">
                            <div class="card-blue">
                                <div class="content">
                                    {{ item.content }}
                                </div>
                                <div class="date">
                                    {{ formatDate(item.created_at) }}
                                </div>
                            </div>
                            <div class="logo" style="margin-inline-start: 5px;">
                                <img width="32" :src="service_provider.image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-100 mb-3 text-center"> 
                    <div  class="spinner-border text-secondary" role="status">
                    </div>
                </div>
            </div>
            <div class="post-correspondence" v-on:keyup.enter="SendMessage">
                <input v-model="message" type="text" name="correspondence" placeholder="اكتب رسالة...">
                <button class="btn-main" @click="SendMessage">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0095238 17.5L20 9L0.0095238 0.5L0 7.11111L14.2857 9L0 10.8889L0.0095238 17.5Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { io } from 'socket.io-client'
import Cookie from 'cookie-universal';
const cookie = Cookie();
const IsUser = window.localStorage.getItem('IsUser')
const socket = io("https://chat.etmaam.com.sa", {
            extraHeaders: {
                "token": IsUser ? cookie.get('Token') : cookie.get('CompanyToken'), // Include the token in the headers
            },
        });
export default {
    data() {
        return {
            joined: false,
            message: '',
            receive_message: [],
            Avatar:cookie.get('Userdata').image
        }
    },
    props: {
        service_provider: Object,
    },
    methods: {
        SendMessage() {
            if(this.message != ''){
                socket.emit("send_message", {
                content: this.message
            });
                this.message = '';
            }
        },
        formatDate(dateString){
            const date = new Date(dateString);
            const year = date.getFullYear(); // Full year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

            return `${year}/${month}/${day}`;
        },
    },
    created() {
       
        // Chat
        socket.emit("join_chat", {
            id: this.$route.params.id,
            type: "Service"
        });

    },
    mounted() {
        socket.on("logs", (data) => {
            // console.log("Logs:", data);
            if (data == 'You joined the chat.') {
                this.joined = true;
                socket.emit("load_messages");
            }
        });
        socket.on("load_messages", (messages) => {
            // console.log("Chat messages loaded:", messages);
            this.receive_message = messages;
        });

        socket.on("receive_message", (message) => {
            this.receive_message.push(message);
            // console.log("New message received:", message);
        });

    }

};
</script>